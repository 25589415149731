import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Header = () => {
  const { logoImg, site } = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          contactEmail
        }
      }
      logoImg: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 175) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <header>
      <div className="inner">
        <div className="logo">
          <Img fixed={logoImg.childImageSharp.fixed} alt="Logo" />
        </div>
        <h1>
          SIMPLY
          <br />
          <strong>THE BEST ONLINE CONVERTER</strong>
          <br />
          FROM <span className="text__highlight">JPG AND PNG</span> TO{" "}
          <span className="text__highlight">WEBP</span>
        </h1>
        <ul className="text__m">
          <li>No upload to server</li>
          <li>Unlimited files</li>
          <li>Unlimited file size</li>
          <li>Quality settings</li>
          <li>Offline functionality</li>
          <li>Easy to use</li>
          <li>FREE</li>
        </ul>
        <h2 className="text__s label">CONTACT</h2>
        <p>
          <a
            className="email"
            href={`mailto:${site.siteMetadata.contactEmail}`}
          >
            {site.siteMetadata.contactEmail}
          </a>
        </p>
      </div>
    </header>
  )
}

export default Header
