/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./Header"

import "../stylesheets/app.scss"

const Layout = ({ children, mainClassName = "" }) => {
  return (
    <>
      <Header />
      <main className={mainClassName}>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  mainClassName: PropTypes.string,
}

export default Layout
